import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "./../components/Layout";
import StandalonePageLayout, {
  WhiteBox,
} from "./../components/StandalonePageLayout";

const TermsConditionsPage = () => {
  const termsQuery = useStaticQuery(graphql`
    {
      craftTermsConditionsTermsConditionsEntry {
        title
        richTextField
      }
    }
  `).craftTermsConditionsTermsConditionsEntry;
  const { title, richTextField } = termsQuery;

  return (
    <Layout
      backgroundColor={"var(--pink)"}
      footerColor={"var(--pink)"}
      title={title}
    >
      <StandalonePageLayout>
        <h1>{title}</h1>
        <WhiteBox>
          <div dangerouslySetInnerHTML={{ __html: richTextField }} />
        </WhiteBox>
      </StandalonePageLayout>
    </Layout>
  );
};

export default TermsConditionsPage;
